<template>
  <div class="heard">
    <div class="heard-center space-between height-100 pointer">
      <img src="../assets/logo.png" alt="" @click="navTo('/')" />
      <div class="row">
        <div
          class="item_nav pointer"
          :class="{
            item_nav_active: item.url == url,
          }"
          v-for="item in navList"
          :key="item.url"
          @click="navTo(item.url)"
        >
          {{ item.text }}
        </div>
        <div class="item_nav item_nav_text pointer" @click="navTo('/login')">LOGIN</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {
      navList: [
        {
          url: "/about",
          text: "ABOUT",
        },
        {
          url: "/serve",
          text: "SERVICE",
        },
        {
          url: "/policy",
          text: "POLICY",
        },
        {
          url: "/blog",
          text: "BLOG",
        },
        {
          url: "/faq",
          text: "FAQ",
        },
        {
          url: "/help",
          text: "HELP&SUPPORT",
        },
      ],
      url: "",
    };
  },

  created() {},

  mounted() {
    let url = this.$route.path;
    if (url == "/blog-detail") {
      this.url = "/blog";
    } else {
      this.url = url;
    }
  },

  methods: {
    navTo(url) {
      if (this.url == url) return;
      this.url = url;
      this.$router.push(url);
    },
  },
};
</script>
<style lang="less" scoped>
.heard {
  width: 100%;
  height: 82px;
  background: #242424;
  .heard-center {
    width: 1220px;
    margin: 0 auto;
    img {
      width: 233px;
      height: 42px;
    }
    .item_nav {
      font-weight: bold;
      color: #ffffff;
      font-size: 14px;
      margin-left: 24px;
      transition: all 0.3s;
    }
    .item_nav:hover {
      color: #ff652b;
    }
    .item_nav_text:hover {
      color: #ffffff;
    }
    .item_nav_text {
      width: 72px;
      height: 34px;
      background: #ff652b;
      border-radius: 50px;
      text-align: center;
      line-height: 34px;
    }
    .item_nav_active {
      color: #ff652b;
      position: relative;
      &::before {
        content: "";
        width: 100%;
        height: 2px;
        background: #ff652b;
        position: absolute;
        bottom: -2px;
        left: 0;
      }
    }
  }
}
</style>
