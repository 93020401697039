<template>
  <div class="faq width-100">
    <heard-vue></heard-vue>
    <div class="width-100 posi-rel">
      <div class="faq-content">
        <div class="faq-heard flex-center width-100">
          <div class="heard-title-bg posi-rel">
            <span>Frequently Asked Questions</span>
            <div class="posi-abs">”</div>
          </div>
          <div class="heard-text">
            <div style="align-items: start;font-weight: bold;line-height: 40px;">
             {{faqObj.content}}
            </div>
            <div class="width-100 row-end">
              <div
                class="flex-center bg-color heard-button pointer"
                @click="navTo('/login')"
              >
                LOGIN
              </div>
            </div>
          </div>
        </div>
        <div
          class="width-100 faq-center row-start"
          v-for="(item, index) in list"
          :key="index"
        >
          <div class="center-title">{{ item.name }}</div>
          <div class="center-item one width-100">
            <div
              class="item-text"
              v-for="itemOne in item.item"
              :key="itemOne.title"
            >
              <div class="row pointer" @click="downClick(itemOne.title)">
                <i
                  class="el-icon-arrow-right"
                  v-if="downId.indexOf(itemOne.title)==-1"
                ></i>
                <i class="el-icon-arrow-down" v-else></i>
                <div class="item-text-title">{{ itemOne.title }}</div>
              </div>
              <div class="item-details" v-if="downId.indexOf(itemOne.title)!=-1">
                <div v-html="itemOne.content">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    <!--  <img
        src="../../assets/about-5.png"
        v-if="!emailShow"
        class="emain-img posi-abs pointer"
        alt=""
        @click="emailShow = true"
      /> -->
      <mail-vue v-if="emailShow" @hide="emailShow = false"></mail-vue>
    </div>
    <feet-vue></feet-vue>
  </div>
</template>

<script>
import heardVue from "../../components/heard.vue";
import feetVue from "../../components/feet.vue";
import mailVue from "../../components/mail.vue";
import api from "../../api/index";
export default {
  components: {
    heardVue,
    feetVue,
    mailVue,
  },

  data() {
    return {
      emailShow: false,
      downId: [],
      list: null,
      faqObj:""
    };
  },

  created() {
    this.getFaq();
    this.getOtherSite()
  },

  mounted() {},

  methods: {
    getOtherSite() {
      api.getOtherSite().then((res) => {
        this.faqObj=res.data.faq
      });
    },
    getFaq() {
      api.getFaq().then((res) => {
        this.list = res.data;
      });
    },
    downClick(id) {
      if (this.downId.indexOf(id) !=-1) {
        this.downId.splice(this.downId.indexOf(id),1)
      } else {
       this.downId.push(id)
      }
    },
    navTo(url) {
      this.$router.push(url);
    },
  },
};
</script>
<style lang="less" scoped>
.faq {
  background: #f8f8f8;
  .faq-content {
    width: 1226px;
    margin: 0 auto;
    padding: 38px 0 88px 0;
    .faq-heard {
      margin-bottom: 78px;
      .heard-title-bg {
        width: 260px;
        height: 250px;
        background: #f3eee7;
        font-weight: bold;
        color: #333333;
        font-size: 44px;
        padding-top: 70px;
        padding-left: 10px;
        margin-right: 133px;
       span{
        font-family: "Sitka Banner";
        font-weight: bold;
       }
        div {
          top: -35px;
          right: 0;
          font-size: 80px;
        }
      }
      .heard-text {
        width: 594px;
        color: #333333;
        font-size: 16px;
        .heard-button {
          width: 106px;
          height: 27px;
          border-radius: 14px;
          color: #ffffff;
          font-size: 16px;
          margin-top: 28px;
        }
      }
    }
    .faq-center {
      margin-bottom: 100px;
      .center-title {
        font-weight: bold;
        color: #333333;
        font-size: 32px;
        width: 130px;
      }
      .center-item {
        margin-left: 224px;
        .item-text {
          font-weight: bold;
          color: #333333;
          font-size: 22px;
          margin-bottom: 38px;
          .item-text-title {
            margin-left: 10px;
          }
          i {
            color: #efac4a;
          }
          .item-details {
            color: #333333;
            font-size: 16px;
            font-weight: 400;
            margin-top: 24px;
            margin-left: 18px;
            div {
              margin-bottom: 20px;
            }
            div:last-child {
              margin-bottom: 0;
            }
          }
        }
        .item-text:last-child{
          margin-bottom: 0;
        }
      }
    }
    .faq-center:last-child {
      margin-bottom: 0;
    }
  }
  .emain-img {
    left: 60px;
    bottom: 70px;
    width: 64px;
    height: 64px;
  }
}
</style>
