<template>
  <div class="feet">
    <!-- 展示区 -->
    <div class="feet-content width-100">
      <div class="feet-center row">
        <!-- 左侧logo区 -->
        <div class="feet_cener_logo">
          <img src="../assets/logo.png" class="feet_logo" alt="" />
          <div class="row">
            <!-- <img src="../assets/feet-1.png" class="feet_1 pointer" @click="navToOne(siteObj.facebook)" alt="" />
            <img src="../assets/feet-2.png" class="feet_2" alt="" /> -->
            <img :src="item.img" class="feet_1 pointer"  alt="" v-for="item in siteObj.contacts" :key="item.img" @click="navToOne(item.link)">
          </div>
        </div>
        <!-- 快速链接区 -->
        <div class="feet_link_text">
          <div class="link_title">QUICK LINKS</div>
          <div
            class="link_text row pointer"
            v-for="item in dataList"
            :key="item.text"
            @click="navTo(item.url)"
          >
            <i class="el-icon-arrow-right"></i>
            <div>{{ item.text }}</div>
          </div>
        </div>
        <!-- 伙伴 -->
        <div class="feet_link_text feet_link_text_one">
          <div class="link_title">PARTNER</div>
          <div class="link_text row pointer" v-for="(item,index) in siteObj.partner" :key="index" @click="navToOne(item.link)">
            <i class="el-icon-arrow-right"></i>
            <div>{{item.name}}</div>
          </div>
        </div>
        <!-- 地址 -->
        <div class="feet_link_text feet_link_text_two">
          <div class="link_title">ADDRESS</div>
          <div class="row feet_email">
            <img src="../assets/feet-3.png" alt="" />
            <span>{{siteObj.email}}</span>
          </div>
          <div class="row feet_visa_img">
           <img :src="siteObj.card_logo" class="width-100 height-100" alt="">
          </div>
        </div>
      </div>
    </div>
    <!-- 编号区 -->
    <div class="width-100 feet-numbering flex-center">
      <div>{{siteObj.copy}}</div>
    </div>
  </div>
</template>

<script>
  import api from "../api/index"
export default {
  components: {},

  data() {
    return {
      dataList: [
        {
          url: "/about",
          text: "About",
        },
        {
          url: "/serve",
          text: "Service",
        },
        {
          url: "/help",
          text: "Help & Support",
        },
        {
          url: "/policy",
          text: "Privacy Policy",
        },
      ],
      url:"",
      siteObj:{}
    };
  },

  created() {
    this.getSite()
  },

  mounted() {
    this.url = this.$route.path;
  },

  methods: {
    getSite(){
      api.getSite().then(res=>{
        this.siteObj=res.data
        document.title=res.data.title
        localStorage.setItem('siteObj',JSON.stringify(res.data))
      }).catch(err=>{
        console.log(err)
      })
    },
    navTo(url) {
      if (this.url == url) return;
      this.url = url;
      this.$router.push(url);
    },
    navToOne(url){
      window.open(url,"_blank");
    }
  },
};
</script>
<style lang="less" scoped>
.feet {
  width: 100%;
  .feet-content {
    height: 458px;
    background: #222222;
    padding-top: 100px;
    .feet-center {
      max-width: 1235px;
      margin: 0 auto;
      align-items: flex-start;
      color: #a9a9a9;
      font-size: 14px;
      .feet_cener_logo {
        margin-right: 76px;
        .feet_logo {
          width: 233px;
          height: 42px;
          margin-bottom: 27px;
        }
        .feet_1,
        .feet_2 {
          margin-right: 17px;
          width: 32px;
          height: 32px;
        }
      }
      .feet_link_text {
        margin-right: 68px;
        .link_title {
          font-weight: bold;
          color: #ffffff;
          font-size: 22px;
          margin-bottom: 23px;
        }
        .link_text {
          margin-bottom: 20px;
          div {
            margin-left: 10px;
          }
        }
        .link_text:hover {
          color: #ff652b;
        }
      }
      .feet_link_text_one {
        margin-right: 75px;
      }
      .feet_link_text_two {
        margin-right: 0;
        .feet_email {
          margin-bottom: 14px;
          img {
            width: 15px;
            height: 10px;
            margin-right: 20px;
          }
        }
        .feet_visa_img {
         width: 210px;
         height: 20px;
        }
      }
    }
  }
  .feet-numbering {
    height: 51px;
    background: #333333;
    font-size: 16px;
    color: #a9a9a9;
  }
}
</style>
